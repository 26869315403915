import React, { useState } from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  Button,
  ContentContainer,
  FeatureFlagged,
  Icon,
  Link,
  Text,
} from 'components';

import AIIcon from 'scenes/components/AIIcon';

import MatrixPaginatedList from '../../components/MatrixPaginatedList';
import Header from '../../components/SkillsHeader';
import CreationModal from './CreationModal';
import CreationWIthAiModal from './CreationWithAiModal';

export default function MatrixList() {
  const { aiFeaturesEnabled } = useCurrentOrganization();
  const [createMatrixModalActive, setCreateMatrixModalActive] = useState(false);
  const [createMatrixWithAiModalActive, setCreateMatrixWithAiModalActive] =
    useState(false);

  return (
    <>
      <Header />
      <ContentContainer>
        <Text>
          <p>
            {__(
              'Skills matrices can be viewed by all your employees. They can be used during a review to evaluate your employees on each skill, depending on their level.'
            )}
          </p>
          <p>
            {__(
              'To know more about skills matrices creation, you can consult %1.',
              <Link to="https://docs.elevo.fr/" isPrimary openInNewTab>
                {__('our help center article')}
              </Link>
            )}
          </p>
        </Text>

        <div className="flex justify-end my-4">
          <FeatureFlagged flag="createMatrixWithAi">
            {aiFeaturesEnabled && (
              <Button
                onClick={() => setCreateMatrixWithAiModalActive(true)}
                className="mr-4"
                color="secondary"
              >
                <AIIcon className="mr-2" />
                {__('Generate a matrix with Elevo AI')}
              </Button>
            )}
          </FeatureFlagged>

          <Button
            onClick={() => setCreateMatrixModalActive(true)}
            color="primary"
          >
            <Icon className="mr-2" name="add" />
            {__('Create a skills matrix')}
          </Button>
        </div>

        <MatrixPaginatedList
          paginationType="url"
          emptyStateProps={{
            iconName: 'table_chart',
            title: __('No skills matrix has been created yet.'),
            description: __(
              'Create your first skills matrix by clicking on the "%1" button',
              __('Create a skills matrix')
            ),
          }}
        />

        {createMatrixModalActive && (
          <CreationModal onClose={() => setCreateMatrixModalActive(false)} />
        )}

        {createMatrixWithAiModalActive && (
          <CreationWIthAiModal
            onClose={() => setCreateMatrixWithAiModalActive(false)}
          />
        )}
      </ContentContainer>
    </>
  );
}
